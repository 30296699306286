import { CONFIG as demo } from './instances/demo';
import { CONFIG as fwtm } from './instances/fwtm';
import { CONFIG as tash } from './instances/tash';
import { CONFIG as rnd } from './instances/rnd';
import { CONFIG as hht } from './instances/hht';
import { CONFIG as nrwt } from './instances/nrwt';
import type { BaseInstanceConfig } from '@models/BaseInstanceConfig';

export type InstanceConfigMapType = Record<
  string,
  Omit<BaseInstanceConfig, 'instanceId'>
>;

export const INSTANCE_TO_CONFIGS: InstanceConfigMapType = {
  demo,
  fwtm,
  hht,
  nrwt,
  rnd,
  tash,
};
