import { MediaTypeEnum } from '../gql/schema';
import {
  ContactType,
  type BaseInstanceConfig,
} from '../models/BaseInstanceConfig';
import { BaseTourDifficulty } from '../models/BaseTourDifficulty';

enum NRWT_TOUR_CATEGORIES {
  AKTIV_UND_OUTDOOR = 14,
  RADFAHREN = 2,
  WANDERN = 1,

  // Aktiv & Outdoor
  BARRIEREFREI = 15,
  BOOTS_UND_KANUTOUR = 16,
  INLINERTOUR = 17,
  JOGGING = 18,
  MOTORISIERTE_TOUR = 19,
  PFERDETOUR = 20,
  THEMENSTRAßE = 21,
  WINTERTOUR = 22,

  // Radfahren
  FERNRADWEG = 11,
  FLUSSRADWEG = 12,
  GRAVELBIKE = 4,
  MOUNTAINBIKE = 5,
  REGIONALE_RADTOUR = 13,
  RENNRAD = 3,

  // Wandern
  FERNWANDERWEG = 6,
  KLETTERSTEIG = 7,
  REGIONALER_WANDERWEG = 8,
  STADTRUNDGANG = 9,
  THEMENWEG = 10,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  defaultWidgetId: 1,
  defaults: {
    hero: {
      filter: { mediaType: [MediaTypeEnum.Image], imageType: { eq: 3 } },
      fallback: [{ mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } }],
    },
    gallery: {
      filter: {
        or: [
          { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
          { mediaType: [MediaTypeEnum.Video] },
        ],
      },
    },
    list: {
      filter: {
        mediaType: [MediaTypeEnum.Image],
        imageType: { oneOf: [2] },
      },
    },
    article: {
      contact: ContactType.CONTACT,
    },
  },
  enabledLanguages: ['de', 'en', 'nl'],
  footerContact: {
    name: 'Tourismus NRW e.V.',
    street: 'Völklinker Straße 4',
    zipcode: '40219',
    city: 'Düsseldorf',
    email: 'info@nrw-tourismus.de',
  },
  footerLogo: '/assets/images/instances/nrwt/logo.white.svg',
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvbnJ3LmlteHBsYXRmb3JtLmRlXC9vYXV0aCIsInN1YiI6IjMzIiwibG5hbWUiOiJ3cy53aGl0ZWxhYmVsLXdpZGdldHMifQ.rXpW8NHiI7OnU-FnwP2xhjMnMqfFqmFo3JBP6VpJhJ9YdvlLUcQkWd2gn8bwnP_CAP4sHtI2fEnElmlelRHipFKoTPoRycRzznhcttvbLzaDme1LBqygPkU0sTmrwcWv9Sa4-1C6mwtJ3GEBtORnN_O5rUPHgmh-AIg25g0hZZmJ9F4clnVA_vukTZkI_vzZR-zXrsW3bDLzXKGNmjj7ZMOUcEb_4x7_L3C15rrnCdqDcVbqX_xKOS4iHYPwunG4AWHWij5mEmdoP-zW7gHuSSJjH4YD3H45IUogqNGQP934Rn8P0tLKSkJE-SbU_SIWZe_v1e-hQMi-UnrL-ix6ig',
  graphqlEndpoint: `https://content-delivery.imxplatform.de/nrwt/imxplatform`,
  headerLogo: '/assets/images/instances/nrwt/logo.colored.svg',
  map: {
    defaultCoords: {
      // hht
      latitude: 51.493155254942224,
      longitude: 7.555403951753447,
    },
  },
  poi: {
    openingHours: {
      hideDateRangeInfo: true,
    },
  },
  tour: {
    categoryIcons: {
      [NRWT_TOUR_CATEGORIES.AKTIV_UND_OUTDOOR]: 'ion:active',
      [NRWT_TOUR_CATEGORIES.MOTORISIERTE_TOUR]: 'ph:motorcycle-fill',
      [NRWT_TOUR_CATEGORIES.WINTERTOUR]: 'lets-icons:winter',
      [NRWT_TOUR_CATEGORIES.RADFAHREN]: 'ion:bicycle',
      [NRWT_TOUR_CATEGORIES.STADTRUNDGANG]: 'icon-park-solid:turn-around',
      [NRWT_TOUR_CATEGORIES.WANDERN]: 'material-symbols:hiking',
      [NRWT_TOUR_CATEGORIES.BOOTS_UND_KANUTOUR]: 'ion:boat-outline',
    },
    difficultyMapping: {
      1: BaseTourDifficulty.EASY,
      2: BaseTourDifficulty.EASY,
      3: BaseTourDifficulty.MEDIUM,
      4: BaseTourDifficulty.HARD,
      5: BaseTourDifficulty.HARD,
    },
  },
};
