import type { Ref } from 'vue';
import { DOMAIN_TO_INSTANCE_MAP } from '../domains.config';
import { INSTANCE_TO_CONFIGS } from '../instances.config';
import type { BaseInstanceConfig } from '@models/BaseInstanceConfig';

const STATE_KEY = 'whl-instance-config';

declare global {
  interface Window {
    __IMXPLATFORM__WIDGET__: {
      widgetBaseUrl: string;
      widgetCurrentHost: string;
      widgetStyleUrl: string;
    };
  }
}

export default function (): Ref<BaseInstanceConfig> {
  return useState<BaseInstanceConfig>(STATE_KEY, (): BaseInstanceConfig => {
    if (
      import.meta.client &&
      window.__IMXPLATFORM__WIDGET__?.widgetCurrentHost
    ) {
      // widget mode
      const host = window.__IMXPLATFORM__WIDGET__.widgetCurrentHost;
      if (host && DOMAIN_TO_INSTANCE_MAP.has(host)) {
        const instanceId = DOMAIN_TO_INSTANCE_MAP.get(host) ?? 'demo';

        return {
          instanceId,
          ...INSTANCE_TO_CONFIGS[instanceId],
        };
      }
      throw new Error('Unknown instance for widget');
    } else {
      // standalone or storybook mode
      const context = useRequestEvent()?.context;
      const instanceId =
        context?.instanceId ?? 'demo'; /* fallback, e.g. for storybook */

      return {
        instanceId,
        ...INSTANCE_TO_CONFIGS[instanceId],
      };
    }
  });
}
